<template>
  <div>
    <el-card style="min-height: 30rem">
      <el-table border :data="list">
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="path" label="路径"></el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="copyLink(s)">复制H5链接</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {getUniacid} from "@/api";

export default {
  name: "entry",
  data(){
    return{
      list:[
        {name:"首页",path:"/pages/index/index"}
      ],
      uni_acid : 0,
    }
  },
  mounted() {
    this.uni_acid = getUniacid();
  },
  methods:{
    copyLink(){
      let link = location.origin + "/app/index?i=" + this.uni_acid
      this.$copyText(link).then(()=>{
        this.$message.success("复制成功")
      })
    }
  }
}
</script>

<style scoped>

</style>